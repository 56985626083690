/* ---- GLOBAL VARIABLES ---- */
:root {
  --primary-blue: rgb(105, 152, 208);
  --secondary-blue: #a8cfff;
  --primary-light: #d2e6ff;
  --primary-dark: rgb(5, 60, 110);
}


/* ---- GENERAL BODY STYLING ---- */
body {
  margin: 0;
  height: 100%;
  min-width: fit-content;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#page-container {
  position: relative;
  min-height:100vh;
}

.content {
  display: flex;
  flex-direction: column;
  padding-bottom: 4rem;
  /* margin-top: 3rem; */
}

button {
  padding: .3rem .9rem;
  background-color: var(--primary-light);
  border-color: var(--primary-light);
  border-radius: 4px;
}

button:hover {
  cursor: pointer;
}

button[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.3);
  border-style: outset;
  cursor: not-allowed;
}

.tooltip-area {
  display: flex;
  flex-direction: column;
  align-items: center;
} 

.tooltip-area #lookup {
  margin-right: 1rem;
}

[role="tooltip"] {
  position: absolute;
  visibility: hidden;
  border-radius: 4px;
  margin-top: 2rem;
  padding: 5px;
  background: var(--primary-dark);
  color: white;
  text-align: center;
}

.tooltip-area:hover [role="tooltip"], .tooltip-area:focus-within [role="tooltip"] {
  visibility: visible;
}

.example-file {
  margin: 1rem;
}

.example-file span {
  font-family: monospace;
  font-size: 1rem;
}

.example-file pre {
  background-color: #e3e1e1;
  border: solid;
  padding: 0.5rem;
  border-radius: 5px;
  white-space: pre-wrap;
}


/* SEARCHBAR */
input[type=search]::placeholder {
  font-size: .9rem;
}

.drag {
  background-color: aliceblue;
  border-color: steelblue;
  color: steelblue;
}

.file-upload {
  display: flex;
  border-style: dotted;
  border-width: 3px;
}

.file-upload:hover, .file-upload > *:hover {
  cursor: pointer;
  border-color: var(--secondary-blue);
}

.file-upload label span {
  color: var(--primary-blue);
}

.file-upload label {
  padding: 1rem;
  width: 100%;
  height: 100%;
  text-align: center;
}

.file-upload label p {
  justify-content: center;
  align-items: center;
}

input[type=file] {
   display: none;
}

.file-error {
  color: red;
}


/* ---- HOME PAGE ---- */
blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  padding: 0.5em 10px;
  quotes: "“" "”" "‘" "’";
  min-width: fit-content;
}


/* ---- LOOKUP / HELP PAGE ---- */
.lookup__api {
  display: flex;
  align-items: center;
  border: 1.5px solid black;
  border-radius: 10px;
  width: fit-content;
  margin-top: 0.7rem;
}

.lookup__api > * {
  margin: auto;
}

.lookup__apiLink {
  color: var(--primary-blue);
  font-weight: 500;
}

.lookup__apiLink:hover {
  color: rgb(20, 63, 117);
}

.lookup__copy {
  border-style: solid;
  margin-left: 1rem;
  padding: 2px 4px;
}

.lookup__copy img {
  height: 1.75rem;
  border-radius: 50%;
}

table, th, td {
  border: 1px solid gray;
  border-collapse: collapse;
}

th, td {
  padding: 7px 10px;
}

.help__link {
  color: gray;
}

.help__link:hover {
  color: var(--primary-blue);
}

.help__response-fields {
  text-transform: capitalize;
}


/* ---- NAVIGATION / FOOTER ---- */
.home__link {
  color: black;
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: bold;
}

.nav__bg {
  background-color: var(--primary-blue);
}

.nav__logo {
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 10;
}

nav {
  text-align: right;
  width: 30%;
}

nav a {
  color: #1a1a1a;
  font-weight: bold;
}

.footer {
  background-color: #1a1a1a;
  color: white;
  display: flex;
  padding: 2.5rem 0;
  flex-direction: column;
  justify-content: center;
  bottom: 0;
  height: 4rem;
  width: 100%;
  max-width: 120rem;
  /* position: fixed; */
}

.footer__content {
  display: flex;
  flex-direction: row;
  margin: 0 1rem 0rem;
  justify-content: space-between;
}

.footer__copyright {
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.6px;
}

.footer__support {
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  text-align: right;
  max-width: 45rem;
}

.footer__sub {
  border-top: 1px solid #585858;
  text-align: right;
  margin: 0 1rem auto;
}

.footer__links {
  margin-top: 1rem;
  font-size: 0.8rem;
  cursor: pointer;
  text-align: right;
}
.footer__links-item {
  color: white;
}

.footer__divider {
  color: #5a5a5a;
  margin: 0 2rem;
}

